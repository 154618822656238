// @ts-nocheck
/* eslint-enable */
/**
 * HPWEB-5118: Measure client side HDP loading performance.
 * Enable logging on your machine by opening the console
 * and running the following command:
 *
 * localStorage.setItem('perfLogging', true);
 */

const activePerfMeasurements = {};

const perfUtils = {
    setMarker(label = '') {
        if (!localStorage.getItem('perfLogging')) {
            return;
        }

        activePerfMeasurements[label] = Date.now();
    },

    endMarker(label = '') {
        if (!localStorage.getItem('perfLogging')) {
            return;
        }

        // Adds a 'Performance measurement' label to console output so
        // that we can easily filter results in the browser console.
        if (activePerfMeasurements[label]) {
            const elapsedTime = Date.now() - activePerfMeasurements[label];
            activePerfMeasurements[label] = false;
            console.warn(`Performance measurement - ${label}: ${elapsedTime}ms`);
        }
    }
};

export default perfUtils;
