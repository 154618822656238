/* eslint-disable no-nested-ternary */
import { css, cx } from 'app/styled-system/css';

export const getListingCardContainerCX = (listingViewed: boolean) => css({
    bg: listingViewed ? 'hpxGrey100' : 'hpxWhite',
    border: '1px solid rgba(209, 209, 213, 0.5)',
    borderRadius: '8px',
    boxShadow: {
        base: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        _hover: '0 8px 32px 0 rgba(0, 0, 0, 0.1)',
        _focus: '0 8px 32px 0 rgba(0, 0, 0, 0.1)'
    },
    cursor: 'pointer',
    height: '334px',
    margin: '0 auto',
    width: { base: '100%', listingCardWidth: '404px' }
});

/** Image */
export const getHeroPhotoContainerCX = (isLoading?: boolean) => css({
    height: '224px',
    position: 'relative',
    width: { base: '100%', listingCardWidth: '402px' },
    borderRadius: '8px 8px 0px 0px',
    animation: isLoading ? 'shimmering' : '',
    bgGradient: isLoading ? 'heroPhotoContainerBgGradient' : '',
    backgroundSize: isLoading ? '1000px 100%' : '',
    // bg overrides bgGradient
    bg: isLoading ? '' : 'hpxGrey400'
});

/** Listing Card Badges */

export const badgeContainerCX = css({
    position: 'absolute',
    display: 'flex',
    flexDir: 'row',
    gap: '4px',
    top: '16px',
    left: '16px'
});


export const badgeOutlineCX = css({
    bgColor: 'hpxWhite',
    borderRadius: '50%',
    border: '0.75px solid #949494',
    height: '26px',
    width: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

export const getInfoBadgeCX = (type?: 'upsell' | 'housing') => css({
    backgroundColor: type === 'upsell' ? '#8B6090' : type === 'housing' ? 'hpPurple500' : '#49284e',
    color: 'hpxWhite',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '600',
    padding: '2px 8px 1px 8px',
    borderRadius: '48px'
});

// /** Listing Details */

export const infoBadgesCX = css({
    display: 'flex',
    flexDir: 'row',
    gap: '4px',
    position: 'absolute',
    right: '16px',
    top: '10px'
});

export const detailedInfoContainerCX = css({
    height: '100px',
    padding: '12px 16px 14px',
    position: 'relative',
    borderTop: '1px solid rgba(209, 209, 213, 0.5)'
});

export const detailedInfoTextCX = css({
    textAlign: 'left',
    color: '#6e6e6e',
    fontSize: '14px',
    lineHeight: '20px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
});

export const listingCardAnchorCX = css({
    color: '#6e6e6e',
    textDecoration: 'none',
    fontWeight: 'normal'
});

export const priceTextCX = css({
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '700',
    display: 'inline-block',
    color: 'hpxBlue700'
});

/** Skeleton Components */

export const skeletonWrapperCX = css({
    maxWidth: '402px',
    lg: { width: '50px' }
});

export const priceSkeletonCX = cx(priceTextCX, css({
    backgroundColor: '#f2f2f2',
    height: '20px',
    width: '160px'
}));

export const detailedInfoTextSkeletonCX = css({
    backgroundColor: 'hpxGrey100',
    height: '14px',
    marginBottom: '6px',
    width: '260px'
});