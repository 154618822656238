import { css } from 'app/styled-system/css';

export const starContainerCX = css({
    position: 'relative',
    display: 'inline-flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '8px'
});

export const getStarImgCX = (isLastStar: boolean) => css({
    display: 'inline-block',
    marginRight: isLastStar? '0px' : '4px',
    color: 'inherit',
    height: '13px',
    width: '13px'
});